<template>
  <div :class="{ 'shrinkreport-content': drawer && !isMobile }" class="mt-16">
    <div>
      <Breadcrumbs class="inlineblock" />
    </div>

    <v-container>
      <v-row>
        <v-col cols="4">
          <v-card tile class="pa-5">
            <v-row v-if="selectedNews">
              <v-spacer />
              <v-btn rounded small color="primary" dark style="text-transform:none" @click="renameNews">
                {{ $t('general.rename') }}
              </v-btn>
              <v-btn style="text-transform:none" rounded small color="red" dark @click="confirmDeleteNews = true">
                {{ $t('general.delete') }}
              </v-btn>
            </v-row>
            <v-subheader>{{ $t('news.news') }}</v-subheader>
            <v-text-field v-model="searchNews" append-icon="mdi-magnify" dense></v-text-field>
            <v-list dense>
              <v-list-item v-for="(item, i) in filteredNews" :key="i" @click="selectNews(item)"
                           :class="{ 'active-news': selectedNews && item.id == selectedNews.id }">
                <v-list-item-content>
                  <v-list-item-title v-text="item.name"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <v-btn @click="isAddingNews = true" class="ma-2 add-news-btn" rounded color="primary" dark>{{
    $t('news.add_news') }}</v-btn>
          </v-card>
        </v-col>
        <v-col cols="8">
          <v-card v-if="selectedNews" class="pa-5">
            <v-row>
              <v-col>
                <v-subheader style="font-size: 0.75rem">{{ $t("news.news_translations") }}</v-subheader>
                <v-data-table
                              :headers="newsTranslationHeaders"
                              :items="selectedNewsTranslations"
                              @click:row="editNewsTranslation"
                              item-key="index"
                              disable-pagination
                              hide-default-footer>
                  <template v-slot:item.description="{ item }">
                    {{ formatDescription(item.description) }}
                  </template>
                </v-data-table>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-autocomplete
                                dense
                                :items="engineeringDepartments"
                                :label="$t('general.engineering_departments')"
                                item-text="name"
                                item-value="id"
                                return-object
                                v-model="selectedNews.editNewsEngineeringDepartments"
                                multiple
                                chips
                                @change="engineeringDepartmentsChanged()"></v-autocomplete>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-autocomplete
                                dense
                                :items="roles"
                                :label="$t('users.role')"
                                item-text="name"
                                item-value="id"
                                return-object
                                v-model="selectedNews.editNewsRoles"
                                multiple
                                chips
                                @change="rolesChanged()"></v-autocomplete>
              </v-col>
            </v-row>

            <v-btn @click="sendNotification()" class="ma-2 add-news-btn" rounded color="primary" dark>{{
    $t('news.sendNotification') }}</v-btn>
          </v-card>
          <br />
        </v-col>
      </v-row>
    </v-container>

    <ConfirmDialog
                   v-if="confirmDeleteNews"
                   v-model="confirmDeleteNews"

                   :title="$t('confirm.head')"
                   :description="$t('news.confirm_delete_news')"

                   :cancelName="$t('reportInfo.no')"
                   :actionName="$t('reportInfo.yes')"
                   :actionFunction="deleteNews" />
    <NamingDialog
                  v-if="isEditingNewsName"
                  v-model="isEditingNewsName"

                  :baseValue="editedNews.name"

                  :headlineName="$t('news.rename_news') + ' ' + editedNews.name"
                  :typeName="$t('general.name')"

                  :cancelName="$t('general.cancel')"
                  :cancelFunction="() => (isEditingNewsName = false)"
                  :actionName="$t('general.rename')"
                  :actionFunction="saveNewsName" />
    <NamingDialog
                  v-model="isAddingNews"
                  v-if="isAddingNews"

                  :headlineName="$t('news.new_news')"
                  :typeName="$t('general.name')"

                  :cancelName="$t('general.cancel')"
                  :cancelFunction="() => (isAddingNews = false)"
                  :actionName="$t('general.add')"
                  :actionFunction="addNewNews" />

    <!-- VIDEO TRANSLATION -->
    <v-dialog
              v-if="isEditingNewsTranslation"
              v-model="isEditingNewsTranslation"
              persistent
              style='z-index:20001;'
              max-width="1200">
      <v-card>
        <v-card-title>
          {{ $t("news.news_translations") }}
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              <v-text-field
                            :label="$t('news.title')"
                            outlined
                            v-model="editedNewsTranslation.title"
                            maxlength="255"></v-text-field>
            </v-col>
            <v-col>
              <v-select
                        :items="availableLanguages"
                        :label="$t('general.language')"
                        v-model="editedNewsTranslation.language"
                        disabled
                        outlined></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <div style="z-index: 10;">
                <editor
                        tinymce-script-src="/tinymce/tinymce.min.js" 
                        v-model="editedNewsTranslation.description"
                        :init="{
    license_key: 'gpl',
    plugins: 'lists link image table code wordcount media autolink',

    toolbar: 'styles | bold italic |' + 'h1 h2 h3 h4 |' +
      'alignleft aligncenter alignright alignjustify |' +
      'bullist numlist outdent indent | link image media',
    height: 600,
    images_upload_handler: this.upload,
    entity_encoding: 'raw',
    language: (this.getLanguageWithoutRegion(this.getLanguage()) == 'fr'?'fr_FR':'en'),
    setup: this.setup,
    paste_as_text: true
  }"
                        output-format="html" />
              </div>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
                 text
                 @click="isEditingNewsTranslation = false"
                 class="no-transform">
            {{ $t("general.cancel") }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
                 color="green"
                 dark
                 @click="saveNewsTranslation"
                 class="no-transform">
            {{ $t("general.save") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from "vuex";
import { parseError, getFormattedName, getLanguage, getLanguageWithoutRegion } from "@/utils/utils";

import Breadcrumbs from "@/components/Breadcrumbs";
import ConfirmDialog from '@/components/dialogs/ConfirmDialog.vue';
import NamingDialog from '@/components/dialogs/NamingDialog.vue';
import authHeader from "@/services/auth-header";
import constants from "@/constants";
import FileService from "@/services/file.service";
const axios = require('axios');

var API_URL = constants.API_URL + "settings/";

export default {
  name: "NewsConfig",
  components: {
    Breadcrumbs,
    ConfirmDialog,
    NamingDialog
  },
  data: () => {
    return {
      news: null,
      selectedNews: null,
      isAddingNews: false,
      isEditingNews: false,
      isEditingNewsName: false,
      editedNews: null,
      confirmDeleteNews: false,
      editedNewsTranslation: null,
      isEditingNewsTranslation: false,
      availableLanguages: [],
      forceNewsTranslationsUpdate: 0,
      engineeringDepartments: [],
      roles: [],
      searchNews: ""
    };
  },
  computed: {
    ...mapState({
      isMobile: (state) => state.isMobile,
      drawer: (state) => state.drawer,
      currentUser: state => state.currentUser,
    }),
    ...mapGetters({
    }),
    filteredNews() {
      if (this.searchNews.length == 0) {
        return this.news;
      }
      return this.news.filter(v => v.name && v.name.toLowerCase().includes(this.searchNews.toLowerCase()));
    },
    newsTranslationHeaders() {
      return [{ text: this.$t('general.language'), value: "language" },
      { text: this.$t('news.title'), value: "title" },
      { text: this.$t('news.description'), value: "description" }];
    },
    selectedNewsTranslations() {
      var translations = [];

      this.forceNewsTranslationsUpdate;

      if (this.selectedNews && this.selectedNews.news_translations) {
        for (var i = 0; i < this.availableLanguages.length; i++) {
          var translation = this.selectedNews.news_translations.find(t => t.language == this.availableLanguages[i]);

          translations.push({
            id: translation ? translation.id : -1,
            description: translation ? translation.description : null,
            title: translation ? translation.title : null,
            language: this.availableLanguages[i],
          });
        }
      }

      return translations;
    },
  },
  methods: {
    ...mapMutations({
      openToast: "openToast",
    }),
    ...mapActions({
      setBreadcrumbs: "setBreadcrumbs",
    }),
    getLanguage,
    detectURLs(content) { var reg_ex = /(https?:\/\/[^\s]+)/gi; return content.match(reg_ex) },
    getLanguageWithoutRegion,
    formatDescription(e) {
      if (e) return e.replace(/<[^>]*>/g, '').substring(0, 100) + '...';
    },
    async upload(blobInfo/*, progress*/) {
      console.log(blobInfo);
      let resp = await FileService.addNewFile(blobInfo.blob(), 'news', 1);
      console.log(resp);
      if (resp?.success) {
        setTimeout(this.update, 500); //tinyMCE does not call update when an image is added
        return resp.data.url.imageRatio;

      }
      else {
        throw resp?.message || 'error';
      }
    },

    setup(editor) {
      editor.on('drop', (e) => {
        if (this.detectURLs(e.dataTransfer.getData('text/html'))?.length){
          this.openToast({ message: 'External image not allowed', duration: 15000, color: "red" })
          e.preventDefault();
        }
      });
    },
    /*
    setup(editor) {
      editor.on('change', this.showContent);
    },
    update() {
      debugger;
      console.log('content:', this.description);
    },
    showContent() {
      debugger;
      console.log(this.editedNewsTranslation.description);
      //setTimeout(this.showContent, 5000);
    },
    */
    getUserFormattedName(user) {
      if (!user) {
        return null;
      }
      return getFormattedName(user.firstname, user.lastname);
    },

    async sendNotification() {
      try {
        // Validation
        let errorMessage = '';

        if (this.selectedNews.notificationSend) errorMessage = 'Notification already sent';
        if (this.selectedNews.news_translations.filter(e => (e.title)).length != this.availableLanguages.length) errorMessage = this.$t('news.allLanguagesM​mustBeCompleted');

        if (!errorMessage) {
          let res = await axios.post(API_URL + "saveNews/sendNotification/" + this.selectedNews.id, {}, { headers: authHeader() });
          if (res.data.message == 'ok') {
            this.selectedNews.notificationSend = true;
            this.openToast({ message: 'Notification sent!', duration: 15000 })
          }
          else {
            errorMessage = 'Error: sendNotification()';
          }
        }

        if (errorMessage) {
          this.openToast({ message: errorMessage, duration: 15000, color: "red" })
          return false;
        }
      }
      catch (err) {
        this.openToast({ message: parseError(err), duration: 15000, color: "red" });
      }
    },

    async selectNews(news) {
      if (this.selectedNews && this.selectedNews.id == news.id) {
        return;
      }

      let res = await axios.get(API_URL + "news/" + news.id, { headers: authHeader() });
      //console.log('selectNews res', res)
      if (res && res.data) {
        this.selectedNews = res.data;

        this.selectedNews.editNewsEngineeringDepartments = JSON.parse(JSON.stringify(this.selectedNews.engineering_departments, null, 2));
        this.selectedNews.editNewsRoles = JSON.parse(JSON.stringify(this.selectedNews.roles, null, 2));
      }
    },
    renameNews() {
      this.isEditingNewsName = true;
      this.editedNews = JSON.parse(JSON.stringify(this.selectedNews));
    },
    saveNewsName(newNewsName) {
      this.isEditingNewsName = false;

      let changes = {
        newsId: this.editedNews.id,
        name: newNewsName,
      };
      this.saveNewsChanges(this.editedNews.id, changes);
    },
    addNewNews(newNewsName) {
      this.isAddingNews = false;

      let newsId = -1;
      let changes = {
        newsId: newsId,
        name: newNewsName,
      };

      this.saveNewsChanges(newsId, changes);
    },
    async saveNewsChanges(newsId, changes) {
      try {

        let res = await axios.post(API_URL + "saveNews", changes, { headers: authHeader() });
        //console.log('saveNews res', res)
        if (res.status == 200) {
          let newsIndex = this.news.findIndex(t => t.id == newsId);
          if (newsIndex >= 0) {
            this.selectedNews.name = res.data.name;
            this.news[newsIndex].name = this.selectedNews.name;
          }
          else {
            this.selectedNews = res.data;
            this.selectedNews.createdBy = this.currentUser;
            this.selectedNews.news_translations = [];

            this.selectedNews.engineering_departments = [];
            this.selectedNews.roles = [];
            this.news.push(this.selectedNews);
          }
        }
        else {
          this.openToast({ message: parseError(res), duration: 15000, color: "red" });
        }
      }
      catch (err) {
        this.openToast({ message: parseError(err), duration: 15000, color: "red" });
      }
    },
    async deleteNews() {
      this.confirmDeleteNews = false;

      let res = await axios.delete(API_URL + "deleteNews/" + this.selectedNews.id, { headers: authHeader() });
      //console.log('deleteNews res', res)
      if (res && res.data) {
        let newsIndex = this.news.findIndex(t => t.id == this.selectedNews.id);
        if (newsIndex >= 0) {
          this.news.splice(newsIndex, 1);
          this.selectedNews = null;
        }
      }
    },
    editNewsTranslation(translation) {
      this.isEditingNewsTranslation = true;
      this.editedNewsTranslation = JSON.parse(JSON.stringify(translation));
    },
    async saveNewsTranslation() {
      let changes = {
        newsId: this.selectedNews.id,
        newsTranslationId: this.editedNewsTranslation.id,
        title: this.editedNewsTranslation.title,
        description: this.editedNewsTranslation.description,
        language: this.editedNewsTranslation.language,
      };
      this.isEditingNewsTranslation = false;

      let res = await axios.post(API_URL + "saveNewsTranslation", changes, { headers: authHeader() });
      //console.log('saveNewsTranslation res', res)
      if (res.status == 200) {
        let translationIndex = this.selectedNews.news_translations.findIndex(t => t.language == this.editedNewsTranslation.language);
        if (translationIndex >= 0) {
          this.selectedNews.news_translations[translationIndex] = res.data;
        }
        else {
          this.selectedNews.news_translations.push(res.data);
        }
        this.forceNewsTranslationsUpdate++;
      }
      else {
        this.openToast({ message: parseError(res), duration: 15000, color: "red" });
      }
    },

    getAddedAndRemovedIds(editList, originalList) {
      let addedIds = [];
      let removedIds = [];

      editList.forEach(vc => {
        let isPresent = false;
        for (let i = 0; i < originalList.length; i++) {
          if (originalList[i].id == vc.id) {
            isPresent = true;
            break;
          }
        }
        if (!isPresent) {
          addedIds.push(vc.id);
        }
      });

      originalList.forEach(vc => {
        let isPresent = false;
        for (let i = 0; i < editList.length; i++) {
          if (editList[i].id == vc.id) {
            isPresent = true;
            break;
          }
        }
        if (!isPresent) {
          removedIds.push(vc.id);
        }
      });

      return {
        addedIds,
        removedIds
      }
    },

    async engineeringDepartmentsChanged() {
      let addedAndRemoved = this.getAddedAndRemovedIds(this.selectedNews.editNewsEngineeringDepartments, this.selectedNews.engineering_departments);

      let changes = {
        newsId: this.selectedNews.id,
        addedEngineeringDepartments: addedAndRemoved.addedIds,
        removedEngineeringDepartments: addedAndRemoved.removedIds,
      }

      let res = await axios.post(API_URL + "saveNews", changes, { headers: authHeader() });
      if (res.status != 200) {
        this.openToast({ message: parseError(res), duration: 15000, color: "red" });
      }

      this.selectedNews.engineering_departments = this.selectedNews.editNewsEngineeringDepartments;
    },
    async rolesChanged() {
      let addedAndRemoved = this.getAddedAndRemovedIds(this.selectedNews.editNewsRoles, this.selectedNews.roles);

      let changes = {
        newsId: this.selectedNews.id,
        addedRoles: addedAndRemoved.addedIds,
        removedRoles: addedAndRemoved.removedIds,
      }

      let res = await axios.post(API_URL + "saveNews", changes, { headers: authHeader() });
      if (res.status != 200) {
        this.openToast({ message: parseError(res), duration: 15000, color: "red" });
      }

      this.selectedNews.roles = this.selectedNews.editNewsRoles;
    }
  },
  async mounted() {
    this.setBreadcrumbs([{ text: this.$t("general.settings"), href: "/settings" }, { text: this.$t("general.news"), href: "/settings/news" },]);

    try {
      let res = await axios.get(API_URL + "news", { headers: authHeader() });
      //console.log('getNews', res)
      if (res && res.data) {
        this.news = res.data.news;
        this.availableLanguages = res.data.languages;
        this.engineeringDepartments = res.data.engineeringDepartments;
        this.roles = res.data.roles;
      }
    }
    catch (err) {
      this.$router.push("/");
    }
  },
};
</script>
<style scoped>
.add-news-btn {
  text-transform: none;
}

.no-transform {
  text-transform: none;
}

.active-news {
  border-radius: 8px;
  background: rgba(0, 159, 77, 0.1);
  color: #009f4d;
}

.v-list {
  max-height: 500px;
  overflow: auto;
}

.logo {
  display: block;
  margin: 0 auto 2rem;
}
</style>

<style>
.tox-statusbar__branding,
.tox-promotion, .tox-dialog .tox-dialog__content-js .tox-dialog__body .tox-dialog__body-nav {
  display: none;
}

</style>